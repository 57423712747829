import React, { Route, Switch } from 'react-router-dom';
import { reactRoutes as routes } from './constants/routes';
import { AccountContainer } from './containers/Account';
import { BlockContainer } from './containers/Block';
import { BlocksContainer } from './containers/Blocks';
import { MainContainer } from './containers/Main';
import { TransactionContainer } from './containers/Transaction';

export const Routes = () => (
  <Switch>
    <Route key="main" path={routes.main} exact component={MainContainer} />
    <Route
      key="blocks"
      path={routes.blocks}
      component={BlocksContainer}
      exact
    />
    <Route path={routes.block} component={BlockContainer} />
    <Route
      key="transaction"
      path={routes.transaction}
      component={TransactionContainer}
    />
    <Route
      key="account"
      path={routes.account}
      component={AccountContainer}
    />
  </Switch>
);
