import React from 'react';
import { useLocation } from 'react-router';
import { Logo } from '../Logo';
import {
  Container, Filling, Wrapper, HeaderLink, ExternalLink, Navbar,
} from './styles';
import { routes } from '../../constants/routes';
import { Text } from '../Typography/Text';
import { GoBack } from '../GoBack';
import { Search } from '../../containers/Search';

export const Header: React.FC = ({ children }) => {
  const location = useLocation();

  return (
    <Wrapper>
      <Container>
        <Navbar>
          {location.pathname === '/' ? null : <GoBack />}
          <HeaderLink to={routes.main}>
            <Logo />
            <Filling>{children}</Filling>
          </HeaderLink>
          <ExternalLink href="https://waterfall.foundation/">
            <Text size={18}>About</Text>
          </ExternalLink>
          <HeaderLink to={routes.blocks}>
            <Text size={18}>Blocks</Text>
          </HeaderLink>
        </Navbar>
        <Search />
      </Container>
    </Wrapper>
  );
};
